import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCreditNotes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/books/credit-note/credit-notes", { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchCreditNote(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/books/credit-note/credit-notes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get("/books/credit-note/clients")
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/books/user/users", { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
